import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, we'll examine the elements of a professional profile.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`By the end of this unit, you will ...`}</p>
    <ul>
      <li parentName="ul">{`create or revise your professional profile to match your career objectives`}</li>
      <li parentName="ul">{`grow your professional network`}</li>
    </ul>
    <h2 {...{
      "id": "the-elephant-in-the-room",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-elephant-in-the-room",
        "aria-label": "the elephant in the room permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The elephant in the room`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c4df750ed8f43b8eb410c5ca57df3938/a878e/elephant-in-the-room.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAAENElEQVQ4yy2T2W8TVxjF57GQOF5mubPfe2dfPPbYnvGSkMZ2xnZsEsdxjEN2wtIEKA2EJDSBCoqEaCkSFFVdH/qCkNoXqqqq+tb+aZXTSt/r0e8cne8QSY6nZUjLECg6i3VONQXDkmxX8fOSm7EmP8y1OuF8r9TtT/aH05fWOjdu9u7cbV3fnb95m6BlyMgQYJVBytmpnGYKpi0YFrZt7DjpWpRptosLvXLvUnV1q3Njb+nj/e7tT4b3HxAAYYAwJaGkIFIQQ9fLTE7idKbQaNx5+vjNu7c/vX+/eXCUa3fLvcGFwUrnxt7K/eONk0ebDz8jzjwjUpRTomwXw9zMtOb7uWp1cmE+Wl259fmTX//5++c/fq/1l/JznfJiP9q6Orx3ePXx05XDByMxQJiGSPVz9eXF7vVrOJMVTMuv1rYP7339y7sff3v/9q8/n333TWd9tbzYmx6uDvYP9p590d8/GNlmMaZkVGpGcxsrfj3idBO6bnUw4BWlvti9cnT3+fevj58/Hexem+kvZ5udxvbO/stXa8enZ5mxArBmBGGp1VR9PyVISY6HjgMtC0BYbMw+efPV7tHdysWOX2/mmu3Nk0d3vny58NEtAiCFU3Retznd5k1Hdj1W0cZJiuQFFmFoW265uHdy+OT1i/xspAaVYqe7efJo/fi0sblDAKxxmsnrlmDYouXypk3JcIKiJXOEVdJuoTZT683ffnhUbDSwX8g326ff/jDcP/BqEUFJiFUMVjUFy5UdT7ScGA0maJAA7FgyNeq5FMqW2b48KLeaoutnatHBqzdzWzt6qUIkOZFVNF4zWdVgsDoBuHOJFI1VNR/GAYdM/eD0MMGyuZnp/pWNqNczSlMbR5/mojktqBC0BNmz95IcjzOspCiP0yDOi16tOdg/MoJweWt1e/dac6k7N1xuLS9FS0uzi4tGUMK5IsGc9RxnOeQX7KkZPazQWKWggvygvnalFDV0P2cHYboYTi8s8JYjpzPrO+vI9QQnQ9CSTMswznI0xNDz9bCCsnkaa1pY8Wotu1RpLvflTO6DJKkHxWh42a/Wo/YsrxpANQhSlChRitEgwfEpURYslzNsRtFgJm9PVd0LM/X5thGWJ1geqIZZrBjZrKTpDNIAVomUIFKSHGNAghdjNGCwKtpp6PmC5YpOWgsqdhDKdpqCCs4XoZejJMRgDSgaAxUiyQsp4X/yGEnFWZ5VDej5WlBmdRNlC0DRaahwho2yBcFKA0UDeIQFSCESLJcUxBhgJwB7PpEao2gaqayqq/lQDUqi40lOhtNtJV90pqqcbnOqBpDy35AJUpITHDdO0THAjZHUuSQZAywpId6wtKAEFC0lQdFOF1oX1UKJQZpkOQyEDES0DEfkOGDHUuQ4xYzTzPmRPjVGUhOAPeMXUyJM8IKSC0gRpUR5lEKSSVGKA+5f1JDxa5+1/UoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "\"The professional elephant in the room.\"",
            "title": "\"The professional elephant in the room.\"",
            "src": "/static/c4df750ed8f43b8eb410c5ca57df3938/fcda8/elephant-in-the-room.png",
            "srcSet": ["/static/c4df750ed8f43b8eb410c5ca57df3938/12f09/elephant-in-the-room.png 148w", "/static/c4df750ed8f43b8eb410c5ca57df3938/e4a3f/elephant-in-the-room.png 295w", "/static/c4df750ed8f43b8eb410c5ca57df3938/fcda8/elephant-in-the-room.png 590w", "/static/c4df750ed8f43b8eb410c5ca57df3938/efc66/elephant-in-the-room.png 885w", "/static/c4df750ed8f43b8eb410c5ca57df3938/c83ae/elephant-in-the-room.png 1180w", "/static/c4df750ed8f43b8eb410c5ca57df3938/a878e/elephant-in-the-room.png 2048w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Whether you love it or hate it, LinkedIn is the most widely used resource by recruiters in industry.  While you are not required to use it, doing so will make your job hunt much easier.  If you elect to use LinkedIn, you will benefit by investing some time into developing your profile and growing your professional network (making connections and engaging with other professionals in your target field).`}</p>
    <h3 {...{
      "id": "why-should-i-use-linkedin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#why-should-i-use-linkedin",
        "aria-label": "why should i use linkedin permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why should I use LinkedIn?`}</h3>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "404px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b552727f17017c4c3828c4d2e7622475/e75d0/hide-the-pain-harold.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "60.810810810810814%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAay6eBpion//xAAbEAACAgMBAAAAAAAAAAAAAAABAgMRAAQSE//aAAgBAQABBQLui8hz1vNmVklU9BVsf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAQABBQAAAAAAAAAAAAAAAAABEBEhMWH/2gAIAQEABj8CRxpheaf/xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUFRkf/aAAgBAQABPyFxDzqXK8r3BZAmoshsjWzbAsbn/9oADAMBAAIAAwAAABBr7//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QTmlP/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qh//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExUUFx/9oACAEBAAE/ELnaGVi3IzJG9XUEsR+x2Y1PTUshb8wulr8Z/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"I'd like to add you to my professional network.\"",
              "title": "Harold would like to add you to his professional network.",
              "src": "/static/b552727f17017c4c3828c4d2e7622475/e75d0/hide-the-pain-harold.jpg",
              "srcSet": ["/static/b552727f17017c4c3828c4d2e7622475/a80bd/hide-the-pain-harold.jpg 148w", "/static/b552727f17017c4c3828c4d2e7622475/1c91a/hide-the-pain-harold.jpg 295w", "/static/b552727f17017c4c3828c4d2e7622475/e75d0/hide-the-pain-harold.jpg 404w"],
              "sizes": "(max-width: 404px) 100vw, 404px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Harold would like to add you to his professional network.`}</figcaption>{`
  `}</figure></p>
    <p>{`I'll admit it: I initially avoided sites like LinkedIn.  I find that the information shared in most social networks (professional or otherwise) often feels disengenous and performative. Despite these misgivings, I came to recognize the value of LinkedIn to the extent that I decided I was willing to trade some of my data (and privacy) for access to their walled garden of information:  `}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Many job applications will ask for your LinkedIn profile URL`}</strong>{`. Some applications may not even give you an option to upload a résumé or CV.  `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`You can find job opportunities from your professional network`}</strong>{`.  As you grow your professional network, more and more employment opportunities will end up in your feed. Your chances of landing a job are much higher when you have a connection to someone in the organization (or the hiring manager).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`You can learn how other people in your industry market themselves`}</strong>{`.  Exploring LinkedIn will provide insight into how some successful professionals in your field describe (market) themeselves.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`You can find information out about current and former employees when applying to a job`}</strong>{`.  Before ever applying a job, I have at times used LinkedIn to identify hiring managers and recruiters to establish soft introductions.  Sometimes after interviewing I have used LinkedIn to identify former employees in similar positions and contacted them to understand what their experience was like at the organization.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Passively collect invitations to interview`}</strong>{`.  LinkedIn claims that over 95% of recruiters use LinkedIn to search for qualified candidates.`}<sup parentName="p" {...{
            "id": "fnref-1"
          }}><a parentName="sup" {...{
              "href": "#fn-1",
              "className": "footnote-ref"
            }}>{`1`}</a></sup>{`  A relatively small investment of time and energy spent on developing your LinkedIn profile can yeild steady returns.  Even if you're not actively seeking a new position, having a (mostly up-to-date) profile on LinkedIn can lead to recruiters contacting you periodically about jobs.  The more accurately your profile reflects your career objectives, the more likely these opportunities are to be relevant to you. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Some people will never bother to look beyond your LinkedIn profile`}</strong>{`.  You may have an amazing portfolio page or blog, but some recruiters may never find it or bother to look beyond your LinkedIn profile.  This doesn't mean there isn't value in developing a portfolio or website beyond LinkedIn, but it may be worthwhile to duplicate some of that information on your LinkedIn profile.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "developing-your-professional-profile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#developing-your-professional-profile",
        "aria-label": "developing your professional profile permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developing your professional profile`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8a4f9e1d5e429a431718e0f1de095e12/61583/link-analysis-profile.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "54.05405405405405%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAChklEQVQoz3WSTW8TVxSGZ1WhNtiE4PhjPu8dz7edGY89sbFjD8E4CZHygRJwhVpREZWC1CIEqgTKBvEP2CAqfgcbuum6YsMvetCYgFjA4tG5Oufe95z36CpLF8osG5KLVpNG2kMbjrGu7tLcPUJu7dP6+S7xL/cQV2fMTk/p3/sDfTBETHKCozlqb0D28DH+0S0qYYzyU7nMD5UqJV1w3nHRRjlaf4w52cIYT/H2buLvHyOn15iePqP32x20LEVNE8S1GdWojT7ZpJZmLAsH5dyFErO9PR4/vM+vDx5Q7abokwn6KEfNRojpDmJzhrN9ne7JCf7BAWqasmKZrPo+FT+g2k5YcVzKhoWSbgz5791b/vnrJh/e/8/dJ08oRT5mnlOLUxqdHlqWYeUbyK0tjOGQVa/JRVPlkmtTCULK9TrF6gqU3cMD3v77jtNHJ7x585q/XzznUsfD3hygDzJqUYgaBxiDBLF5Gb0fU2s5VAPBijQprVZYWi4v+LG0hOIHEfn8d+Z37rMz6CGDFqpsorsemu2iSWeBWkTbWdTqusVqQ6emWdR0QVWzqBsC6QYoutVEuBFJuk4YrNEwbVTDpq4JqqpJraB4qFo0DEldF1jNkDDpI5o+0g0RToDtRThBGyVdH3LjeM7h8Zy9G8dcmW4zGOVkgw3anYy1bp8o7tLu9NCFQ00zcaIO4+JLxSlxd521dH1xp2immNLBdlyEtBGyuejitxLCdoIX9wm6E2SQYNkeajG9adMwiumNhdXCRfXMRVFTWuN9rj96xfafL4nzQ7wgwnKChb2GYZ/xyeqnnPwi/C2U8PIOG7efMrr9lPjKIUnaRXrRQkA1zwS+Eml85/xZ8CNw6lYKmuK+iwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"Link Analysis\"",
              "title": "Don't fabricate information on your profile page.",
              "src": "/static/8a4f9e1d5e429a431718e0f1de095e12/fcda8/link-analysis-profile.png",
              "srcSet": ["/static/8a4f9e1d5e429a431718e0f1de095e12/12f09/link-analysis-profile.png 148w", "/static/8a4f9e1d5e429a431718e0f1de095e12/e4a3f/link-analysis-profile.png 295w", "/static/8a4f9e1d5e429a431718e0f1de095e12/fcda8/link-analysis-profile.png 590w", "/static/8a4f9e1d5e429a431718e0f1de095e12/efc66/link-analysis-profile.png 885w", "/static/8a4f9e1d5e429a431718e0f1de095e12/c83ae/link-analysis-profile.png 1180w", "/static/8a4f9e1d5e429a431718e0f1de095e12/61583/link-analysis-profile.png 1616w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Don't fabricate information on your profile page.`}</figcaption>{`
  `}</figure></p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/help/linkedin/answer/a554351",
        "target": "_self",
        "rel": "nofollow"
      }}>{`This 3-minute video will walk you through how to edit your LinkedIn profile and make decisions about what information to share`}</a>{`.  When developing your LinkedIn profile, you will benefit from analyzing profiles of early career professionals in positions you're targeting.  Of course having a job doesn't mean someone is great at marketing themselves, but examining a large enough sample will reveal some trends in how information is highlighted.`}</p>
    <h3 {...{
      "id": "profile-headline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#profile-headline",
        "aria-label": "profile headline permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Profile headline`}</h3>
    <p>{`You're only permitted 120 characters for your profile headline.  Some people elect to use this for their current job title, but you can use it to convey a few key pieces of information (ex. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`JOB TITLE @ ORG | Seeking X`}</code>{`), especially a key talent or passion as a way of highlighting what unique value you bring to an organization (curiosity, expertise, passion, etc.).`}</p>
    <h3 {...{
      "id": "profile-summary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#profile-summary",
        "aria-label": "profile summary permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Profile summary`}</h3>
    <p>{`You can think of your profile summary (the `}<strong parentName="p">{`About`}</strong>{` section) as a general cover letter that `}<strong parentName="p">{`covers you expertise and career goals`}</strong>{`. Try to tell a compelling story that provides some background on who you are, your goals, and `}<strong parentName="p">{`what you bring to the table`}</strong>{`.  Keep your summary succinct and avoid unncessary terms.`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Describing your value`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Getting into the mindset of answering the question of what unique value you contribute to an organization will help you throughout your career (interviewing, crafting a portfolio of accomplishments for promotion, etc.). This is not the same as bragging, but rather learning to highlight how your work solves real problems for your organization and its customers.`}</p></div></div>
    <p>{`If you're feeling `}<em parentName="p">{`really`}</em>{` stuck, you can try using the following template to get started ...`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`I have spent the last two years completing a graduate degree in {HLT/Computational Linguistics/NLP/...}, 
and am now seeking full-time employment in the following areas: _________ and _______. 
I am looking for organizations that value _______ .
My work as a _________ and _________ provided me with hands-on experience on how to apply my knowledge of _______ 
to solve real problems in the _______ industry. `}</code></pre></div>
    <h3 {...{
      "id": "profile-photo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#profile-photo",
        "aria-label": "profile photo permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Profile photo`}</h3>
    <p>{`Include a professional headshot. If you don't have such a photo, you might consider using a generative multimodal model to take a not-quite-professional photo of you along with a prompt to produce a professional style photo.`}</p>
    <h3 {...{
      "id": "background-photo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#background-photo",
        "aria-label": "background photo permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background photo`}</h3>
    <p>{`This may seem silly, but consider using a non-stock background photo (profile background image) that connects to who you are and the story you're telling elsewhere in your profile.  A unique background photo can help people remember your profile.  Some consider the background photo to be a proxy for a business card.`}</p>
    <h3 {...{
      "id": "experience",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#experience",
        "aria-label": "experience permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Experience`}</h3>
    <p>{`I recommend only including (volunteer or paid) experience that is relevant to your career goals.  Aside from full-time employment at an organization, experience can involve research roles (ex. RAships), internships, consulting, and other contract work.  Some suggest that including your first job provides a nice talking point.  For each entry under experience, try to come up with 2-3 bullets that summarize the nature of the role and your major contributions.`}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Quantify contributions`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Try to quantify contributions whenever possible (ex. 20% reduction in false positives)`}</p></div></div>
    <p>{`You can see some examples of experience summaries in `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/gushahnpowell/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`this profile`}</a>{`.  Try to look at several (especially for people in positions you're targeting) to get a sense of what others choose to highlight.`}</p>
    <h3 {...{
      "id": "skills",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#skills",
        "aria-label": "skills permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Skills`}</h3>
    <p>{`Spend some time identifying some key skills that you have acquired which you know (through analysis of job ads and other profiles) to be relevant to positions you're targeting.  Avoid a laundry list of skills.  Any skills and proficiencies listed will be much more impactful if you can tie them to specific positions or projects where you developed the skill or were able to demonstrate your expertise.`}</p>
    <h3 {...{
      "id": "interests",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#interests",
        "aria-label": "interests permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interests`}</h3>
    <p>{`If there are research topics, companies, and people that particularly interest you (and tie to your career goals), consider listing them under `}<strong parentName="p">{`Interests`}</strong>{` to give others a sense of your values.`}</p>
    <h3 {...{
      "id": "publications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#publications",
        "aria-label": "publications permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Publications`}</h3>
    <p>{`If you have publications and/or research presentations, consider listing them in your profile for better visiblity.  `}</p>
    <h3 {...{
      "id": "projects",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#projects",
        "aria-label": "projects permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Projects`}</h3>
    <p>{`You're likely not the only A student in your MS or PhD program.  Projects can help you stand out from peers and demonstrate that you can apply what you learn beyond the classroom.  `}</p>
    <h3 {...{
      "id": "expand-your-professional-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#expand-your-professional-network",
        "aria-label": "expand your professional network permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Expand your professional network`}</h3>
    <p>{`Connect with peers and connections of connections (where you have a mutual connection in common) in your target industry to expand your profile's reach and potentially discover new opportunities.`}</p>
    <p>{`For connections that you don't know personally, writing a short message introducing yourself and your goals might make your request less likely to go ignored.  Spend a bit of time crafting a generic message that you can reuse and tweak.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://www.linkedin.com/pulse/how-recruiters-find-evaluate-candidates-3wvdf/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://www.linkedin.com/pulse/how-recruiters-find-evaluate-candidates-3wvdf/`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><a parentName="li" {...{
            "href": "https://www.linkedin.com/business/talent/blog/product-tips/words-you-should-not-use-on-linkedin-profile-infographic",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://www.linkedin.com/business/talent/blog/product-tips/words-you-should-not-use-on-linkedin-profile-infographic`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      